:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

.breadcrumbs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0 0 0.35714rem;
  padding: 0; }
  .breadcrumbs .item {
    align-items: center;
    display: flex;
    cursor: pointer;
    white-space: nowrap; }
    .breadcrumbs .item .breadcrumb-link {
      color: #08554e;
      font-weight: bold;
      opacity: .6;
      font-size: .72em;
      font-weight: bold;
      letter-spacing: .6px;
      text-decoration: none;
      text-transform: uppercase;
      transition: opacity 0.2s ease-in-out; }
      .breadcrumbs .item .breadcrumb-link:hover {
        opacity: 1; }
      .breadcrumbs .item .breadcrumb-link.last {
        opacity: .4;
        pointer-events: none; }
    .breadcrumbs .item .separator {
      color: #b16f6f;
      margin: 0 0.35714rem;
      opacity: .4;
      font-size: 0.8em; }
