:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

.menu {
  --accent: #08554e;
  --accent-contrast: #8b572a;
  --sub-menu-bg: #08554e;
  display: block;
  letter-spacing: 0.2px;
  margin: 0;
  max-width: 100%;
  height: 100%;
  padding: 0;
  transition: 0.2s opacity 0.2s ease-in-out;
  width: inherit;
  overflow: auto; }
  .menu .main-menu-wrap {
    width: inherit; }
    .menu .main-menu-wrap .menu-ul {
      list-style-type: none;
      margin: 0;
      padding: 2.14286rem 0.71429rem; }
      .menu .main-menu-wrap .menu-ul .sub {
        background: rgba(255, 255, 255, 0.07);
        border-radius: 10px;
        list-style-type: none;
        overflow: hidden;
        transition: transform 0.2s ease-in-out;
        padding: 0.35714rem 0; }
        .menu .main-menu-wrap .menu-ul .sub .menu-item {
          align-items: center;
          display: flex;
          margin: 0;
          padding: 0 0.35714rem 0 2.28571rem; }
          .menu .main-menu-wrap .menu-ul .sub .menu-item .item-link .link-text {
            font-size: 1rem;
            opacity: 0.3; }
          .menu .main-menu-wrap .menu-ul .sub .menu-item:after {
            display: block;
            border: 2px solid currentColor;
            border-radius: 50%;
            content: '';
            height: 8px;
            margin: -4px 0 0;
            opacity: 0.3;
            position: absolute;
            top: 50%;
            transition: opacity 0.2s ease-in-out;
            left: 1.07143rem;
            width: 8px; }
          .menu .main-menu-wrap .menu-ul .sub .menu-item:first-child {
            margin-top: 0.17857rem; }
          .menu .main-menu-wrap .menu-ul .sub .menu-item:last-child {
            margin-bottom: 0.17857rem; }
          .menu .main-menu-wrap .menu-ul .sub .menu-item:hover > .item-link, .menu .main-menu-wrap .menu-ul .sub .menu-item.active > .item-link {
            background: none !important; }
            .menu .main-menu-wrap .menu-ul .sub .menu-item:hover > .item-link .link-text, .menu .main-menu-wrap .menu-ul .sub .menu-item.active > .item-link .link-text {
              opacity: 1; }
          .menu .main-menu-wrap .menu-ul .sub .menu-item:hover:after, .menu .main-menu-wrap .menu-ul .sub .menu-item.active:after {
            opacity: 1; }
          .menu .main-menu-wrap .menu-ul .sub .menu-item.active > .item-link .link-text {
            color: #08554e;
            color: var(--accent); }
      .menu .main-menu-wrap .menu-ul .menu-item {
        color: #fff;
        cursor: pointer;
        padding: 0.17857rem 0;
        margin: 0.35714rem 0;
        border-radius: 20px;
        position: relative; }
        .menu .main-menu-wrap .menu-ul .menu-item.group {
          margin: 0;
          padding-bottom: 0.17857rem; }
        .menu .main-menu-wrap .menu-ul .menu-item .group-title {
          color: #08554e;
          color: var(--accent);
          display: block;
          font-size: 0.9em;
          letter-spacing: 0.8px;
          padding: 1.07143rem 0.71429rem 0;
          text-transform: uppercase; }
        .menu .main-menu-wrap .menu-ul .menu-item:first-child .group-title {
          padding-top: 0; }
        .menu .main-menu-wrap .menu-ul .menu-item .item-link {
          align-items: center;
          border-radius: 500px;
          color: currentColor;
          display: flex;
          height: 100%;
          justify-content: space-between;
          min-height: 2.14286rem;
          padding: 0.35714rem 0.35714rem;
          position: relative;
          text-decoration: none;
          transition: background 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out; }
          .menu .main-menu-wrap .menu-ul .menu-item .item-link .link-text {
            flex-grow: 2;
            flex-shrink: 1;
            font-size: 1.1em;
            line-height: 1.25;
            margin: 0 0.35714rem;
            transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out; }
          .menu .main-menu-wrap .menu-ul .menu-item .item-link .link-badge {
            margin: 0 0.35714rem; }
          .menu .main-menu-wrap .menu-ul .menu-item .item-link .link-icon {
            color: inherit;
            font-size: 1.2em;
            margin: 1px 0.71429rem 0 0.35714rem;
            min-width: 17px;
            opacity: 0.3;
            padding: 0;
            transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out; }
          .menu .main-menu-wrap .menu-ul .menu-item .item-link .link-caret {
            margin: 3px 0.35714rem 0;
            font-size: 1.2em;
            transition: transform 0.2s ease-in-out, color 0.2s ease-in-out; }
        .menu .main-menu-wrap .menu-ul .menu-item:hover > .item-link .link-icon {
          opacity: 1; }
        .menu .main-menu-wrap .menu-ul .menu-item.active:not(.has-sub) > .item-link {
          background: #08554e;
          background: var(--accent);
          color: #8b572a;
          color: var(--accent-contrast); }
        .menu .main-menu-wrap .menu-ul .menu-item.active > .item-link .link-caret {
          transform: rotate(90deg); }
        .menu .main-menu-wrap .menu-ul .menu-item.has-sub {
          position: relative; }
          .menu .main-menu-wrap .menu-ul .menu-item.has-sub > .item-link {
            background: none !important;
            position: relative; }
            .menu .main-menu-wrap .menu-ul .menu-item.has-sub > .item-link .caret {
              display: block; }
          .menu .main-menu-wrap .menu-ul .menu-item.has-sub:hover > .item-link .link-icon, .menu .main-menu-wrap .menu-ul .menu-item.has-sub.active > .item-link .link-icon {
            opacity: 1; }
          .menu .main-menu-wrap .menu-ul .menu-item.has-sub:hover > .item-link .caret, .menu .main-menu-wrap .menu-ul .menu-item.has-sub.active > .item-link .caret {
            transform: rotate(90deg); }
  @media (min-width: 992px) {
    .menu.horizontal {
      margin: 0 !important;
      overflow: visible; } }
  @media (min-width: 992px) {
    .menu.horizontal .main-menu-wrap .mCustomScrollBox {
      overflow: visible; }
      .menu.horizontal .main-menu-wrap .mCustomScrollBox .mCSB_container {
        overflow: visible; }
    .menu.horizontal .main-menu-wrap .mCSB_scrollTools {
      display: none !important; }
    .menu.horizontal .main-menu-wrap .menu-ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0; }
      .menu.horizontal .main-menu-wrap .menu-ul > .menu-item > .group-title {
        display: none; }
      .menu.horizontal .main-menu-wrap .menu-ul .menu-item {
        padding: 0; }
        .menu.horizontal .main-menu-wrap .menu-ul .menu-item:after {
          content: '';
          display: none;
          position: absolute;
          width: 100%;
          height: 10px;
          background: transparent;
          z-index: 2; }
        .menu.horizontal .main-menu-wrap .menu-ul .menu-item .item-link {
          padding: 0.71429rem 0.71429rem; }
          .menu.horizontal .main-menu-wrap .menu-ul .menu-item .item-link .link-caret {
            transform: rotate(90deg); }
        .menu.horizontal .main-menu-wrap .menu-ul .menu-item .sub {
          background: #08554e;
          background: var(--sub-menu-bg);
          border-radius: 10px;
          box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.08);
          margin-top: 5px;
          display: block !important;
          height: auto !important;
          left: 0;
          opacity: 0;
          padding: 0.71429rem 0;
          position: absolute;
          transform: translateY(15px);
          transition: 0.2s opacity 0s ease-in-out, 0s visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
          visibility: hidden !important;
          width: 17.85714rem;
          z-index: 500; }
          .menu.horizontal .main-menu-wrap .menu-ul .menu-item .sub:before {
            bottom: 2.85714rem; }
          .menu.horizontal .main-menu-wrap .menu-ul .menu-item .sub .menu-item {
            width: 100%; }
            .menu.horizontal .main-menu-wrap .menu-ul .menu-item .sub .menu-item:after {
              display: none; }
            .menu.horizontal .main-menu-wrap .menu-ul .menu-item .sub .menu-item .item-link {
              height: auto;
              min-height: initial;
              padding-top: 0.35714rem;
              padding-bottom: 0.35714rem; }
        .menu.horizontal .main-menu-wrap .menu-ul .menu-item:hover:after {
          display: block; }
        .menu.horizontal .main-menu-wrap .menu-ul .menu-item:hover > .sub {
          opacity: 1;
          transform: translateY(5px);
          transition: 0.2s opacity 0s ease-in-out, 0s visibility 0s ease-in-out, transform 0.2s ease-in-out;
          visibility: visible !important; }
          .menu.horizontal .main-menu-wrap .menu-ul .menu-item:hover > .sub:first-child {
            margin-top: 5px; }
        .menu.horizontal .main-menu-wrap .menu-ul .menu-item.active {
          background: transparent !important; }
          .menu.horizontal .main-menu-wrap .menu-ul .menu-item.active:after {
            display: block; }
          .menu.horizontal .main-menu-wrap .menu-ul .menu-item.active > .item-link .link-icon {
            opacity: 1; }
        .menu.horizontal .main-menu-wrap .menu-ul .menu-item:last-child .sub {
          left: auto;
          right: 0; }
        .menu.horizontal .main-menu-wrap .menu-ul .menu-item.full-sub {
          position: static; }
          .menu.horizontal .main-menu-wrap .menu-ul .menu-item.full-sub .sub {
            width: 100%; } }
