.top-up-config .ant-card-head-wrapper .ant-form-item {
  margin: 0;
}
.top-up-config .ant-card-head-title {
  padding: 0;
}

.top-up-config .ant-form-item-label label {
  margin: 0;
}
