:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

@keyframes loaderAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.footer {
  background: #FBFBFB;
  border-top: 1px solid #E9E9E9;
  display: block;
  height: 2.85714rem; }
  .footer .footer-wrap {
    background: inherit;
    height: 100%;
    margin: 0 auto;
    padding: 0.35714rem 1.42857rem;
    position: relative; }
    .footer .footer-wrap .row {
      height: 100%; }
    .footer .footer-wrap .info {
      font-size: .8rem;
      opacity: .8; }
    .footer .footer-wrap .footer-skeleton {
      background: inherit;
      height: 100%;
      left: 0;
      padding: 0.71429rem 1.42857rem;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.2s opacity 0s ease-in-out, 0s visibility 0s ease-in-out;
      z-index: 990; }
      .footer .footer-wrap .footer-skeleton .row {
        height: inherit; }
      .footer .footer-wrap .footer-skeleton .bg {
        background: #ebebeb; }
      .footer .footer-wrap .footer-skeleton .bg-1 {
        background: #f5f5f5; }
      .footer .footer-wrap .footer-skeleton .animated-bg {
        overflow: hidden;
        position: relative; }
      .footer .footer-wrap .footer-skeleton .animated-bg:before {
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: loaderAnimation;
        animation-timing-function: linear;
        background: transparent;
        background: linear-gradient(to right, transparent 10%, rgba(255, 255, 255, 0.5) 18%, transparent 33%);
        background-size: 800px 100%;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
      .footer .footer-wrap .footer-skeleton .page-breadcrumbs {
        margin: -0.35714rem; }
        .footer .footer-wrap .footer-skeleton .page-breadcrumbs .item {
          border-radius: 3px;
          display: block;
          height: 1.07143rem;
          margin: 0 0.35714rem;
          width: 230px; }
      .footer .footer-wrap .footer-skeleton .info {
        align-items: center;
        display: flex;
        margin: -0.35714rem; }
        .footer .footer-wrap .footer-skeleton .info .version {
          border-radius: 3px;
          display: block;
          height: 0.71429rem;
          margin: 0 0.35714rem;
          width: 80px; }
        .footer .footer-wrap .footer-skeleton .info .settings {
          background: #08554e;
          border-radius: 500px;
          display: block;
          height: 1.42857rem;
          margin: 0 0.35714rem;
          width: 1.42857rem; }
  .footer.boxed .footer-wrap {
    max-width: 1140px;
    max-width: var(--boxed-width); }
  .footer.loaded .footer-wrap .footer-skeleton {
    opacity: 0;
    transition: 0.2s opacity 0.2s ease-in-out, 0s visibility 0.4s ease-in-out;
    visibility: hidden; }
