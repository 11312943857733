:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

.tc-autocomplete {
  border-radius: 20px;
  display: block;
  outline: none;
  width: 100%;
  transition: none; }
  .tc-autocomplete:hover, .tc-autocomplete:focus {
    transition: none; }
  .tc-autocomplete .tc-input {
    border-radius: inherit;
    transition: none; }
  .tc-autocomplete .autocomplete-list {
    display: none;
    position: relative;
    z-index: 999; }
    .tc-autocomplete .autocomplete-list.opened {
      display: block; }
    .tc-autocomplete .autocomplete-list .list-ul {
      background: #fff;
      border: 1px solid #fff;
      box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      color: #000;
      display: flex;
      flex-direction: column;
      list-style-type: none;
      left: 0;
      margin: 0;
      margin-top: 0.71429rem;
      max-height: 14.28571rem;
      overflow: auto;
      padding: 0.71429rem 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 999; }
      .tc-autocomplete .autocomplete-list .list-ul .list-li {
        cursor: pointer;
        padding: 0.35714rem 0; }
        .tc-autocomplete .autocomplete-list .list-ul .list-li .li-text {
          border-radius: 13px;
          margin-left: 1.07143rem;
          display: inline;
          padding: 0.17857rem 0.71429rem;
          margin-right: auto;
          transition: background 0.2s ease-in-out; }
        .tc-autocomplete .autocomplete-list .list-ul .list-li ::ng-deep .val-mark .li-text {
          background: none;
          color: #08554e;
          pointer-events: none; }
        .tc-autocomplete .autocomplete-list .list-ul .list-li:hover .li-text, .tc-autocomplete .autocomplete-list .list-ul .list-li.active .li-text {
          background: rgba(157, 92, 229, 0.1); }
        .tc-autocomplete .autocomplete-list .list-ul .list-li:last-child {
          box-shadow: none; }
  .tc-autocomplete.input-sm {
    border-radius: 15px; }
  .tc-autocomplete.input-lg {
    border-radius: 30px; }
