:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

.tc-list {
  padding: 0;
  margin: 0;
  list-style-type: none; }
  .tc-list .tc-list-item {
    align-items: center;
    background-color: #fff;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    display: flex;
    min-height: 50px;
    padding: 0.71429rem 2.14286rem;
    width: 100%; }
    .tc-list .tc-list-item:not(:last-child) {
      margin-bottom: 0.71429rem; }
