:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

.navbar {
  background: #fff;
  color: var(--main-color);
  display: block;
  position: relative;
  transition: box-shadow 0.2s ease-in-out;
  will-change: box-shadow;
  z-index: 990; }
  .navbar .navbar-wrap {
    align-items: center;
    background: inherit;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    min-height: inherit;
    padding: 0 0.71429rem;
    position: relative; }
    .navbar .navbar-wrap > * {
      margin: 0 0.71429rem; }
    .navbar .navbar-wrap .navbar-toggle {
      align-items: center;
      border: none;
      background: none;
      color: inherit;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      font-size: 22px;
      justify-content: center;
      margin: 0 0.71429rem;
      height: 40px;
      width: 35px; }
      .navbar .navbar-wrap .navbar-toggle span {
        background: #2fa7ff;
        border-radius: 8px;
        display: block;
        height: 3px;
        margin: 4px 0;
        transition: background 0.2s ease-in-out;
        width: 35px; }
      .navbar .navbar-wrap .navbar-toggle:hover span {
        background: #2fa7ff; }
      .navbar .navbar-wrap .navbar-toggle .navbar-close {
        color: #000;
        cursor: pointer;
        font-size: 30px;
        position: absolute;
        right: 0.71429rem;
        top: 1.07143rem;
        width: auto !important;
        z-index: 999; }
      .navbar .navbar-wrap .navbar-toggle .add-patient {
        height: 100%;
        padding: 1.42857rem 2.85714rem; }
      .navbar .navbar-wrap .navbar-toggle .assistant-menu {
        display: block;
        padding: 0 2.85714rem 1.42857rem; }
        .navbar .navbar-wrap .navbar-toggle .assistant-menu .link {
          color: #fff;
          display: block;
          font-weight: 700;
          opacity: 0.4;
          padding: 0.35714rem 0;
          transition: opacity 0.2s ease-in-out; }
          .navbar .navbar-wrap .navbar-toggle .assistant-menu .link .tc-icon {
            color: #fff;
            margin-right: 0.71429rem;
            padding: 0; }
          .navbar .navbar-wrap .navbar-toggle .assistant-menu .link:hover {
            opacity: 1; }
  .navbar.boxed .navbar-wrap {
    background: none;
    max-width: 1140px;
    max-width: var(--boxed-width); }
  @media (max-width: 991px) {
    .navbar.horizontal-vertical {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateX(-18.57143rem);
      transition: transform 0.2s ease-in-out;
      width: 18.57143rem;
      will-change: transform, width;
      z-index: 1000; }
      .navbar.horizontal-vertical .navbar-wrap {
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        overflow: auto;
        padding: 0;
        width: 18.57143rem; }
        .navbar.horizontal-vertical .navbar-wrap > * {
          margin: 0;
          width: 100%; }
        .navbar.horizontal-vertical .navbar-wrap .logo {
          min-height: 60px;
          background: #fff;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
          z-index: 99; }
      .navbar.horizontal-vertical.opened {
        transform: translateX(0); }
      .navbar.horizontal-vertical.loading .main-menu {
        opacity: 0; } }
  .navbar.vertical {
    background-image: linear-gradient(188deg, #cd5447, #9d5ce5 65%);
    display: flex;
    justify-content: flex-end;
    height: 100%;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    transition: transform 0.2s ease-in-out;
    width: 18.57143rem;
    will-change: transform, width;
    -webkit-overflow-scrolling: touch;
    z-index: 1000; }
    @media (max-width: 991px) {
      .navbar.vertical {
        transform: translateX(-18.57143rem); }
        .navbar.vertical.opened .overlay {
          display: block; } }
    .navbar.vertical .navbar-wrap {
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      padding: 0;
      width: 18.57143rem; }
      .navbar.vertical .navbar-wrap > * {
        margin: 0;
        width: 100%; }
      .navbar.vertical .navbar-wrap .logo {
        min-height: 60px;
        background: #fff;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
        z-index: 99; }
    .navbar.vertical.opened {
      transform: translateX(0); }
      .navbar.vertical.opened .overlay {
        display: block; }

.overlay {
  background: rgba(0, 0, 0, 0.16);
  visibility: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  transform: translateX(0);
  transition: visibility 0.3s ease-in-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  z-index: 9996; }
  @media (max-width: 991px) {
    .overlay.opened {
      opacity: 1;
      display: block;
      visibility: visible;
      transform: translateX(18.57143rem);
      transition: visibility 0s linear, opacity 0.25s ease-in-out, transform 0.25s ease-in-out; } }
