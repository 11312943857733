.soft-pin-config .ant-card-head-wrapper .ant-form-item {
  margin: 0;
}
.soft-pin-config .ant-card-head-title {
  padding: 0;
}

.soft-pin-config .ant-form-item-label label {
  margin: 0;
}
