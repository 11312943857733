.ant-div-right {
  display: flex;
  justify-content: flex-end;
}

.pd-left-12 {
  padding-left: 12px !important;
}

.pd-right-12 {
  padding-right: 12px !important;
}

.ant-alert {
  margin-bottom: 12px;
}

.ant-pagination.mini .ant-pagination-item {
  line-height: 30px;
  margin-right: 8px;
}

.ant-pagination.mini .ant-pagination-prev {
  margin-right: 8px;
}