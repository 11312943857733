:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

.logo {
  display: block;
  height: 60px;
  position: relative;
  padding: 0.71429rem 1.42857rem;
  margin: 0; }
  .logo .logo-wrap {
    align-items: center;
    color: inherit;
    display: flex;
    height: 100%;
    text-decoration: none; }
    .logo .logo-wrap .logo-img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      vertical-align: top; }
    .logo .logo-wrap .logo-text {
      display: block;
      font-size: 20px;
      line-height: 1;
      margin-left: 1.42857rem; }
