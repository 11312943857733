/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after,
  p::first-letter,
  div::first-letter,
  blockquote::first-letter,
  li::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #292b2c;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  cursor: help; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

a {
  color: #0275d8;
  text-decoration: none; }
  a:focus, a:hover {
    color: #014c8c;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle; }

[role="button"] {
  cursor: pointer; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse;
  background-color: transparent; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #636c72;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
textarea {
  line-height: inherit; }

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  cursor: not-allowed; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit; }

input[type="search"] {
  -webkit-appearance: none; }

output {
  display: inline-block; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-left: 0.25rem solid #eceeef; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #636c72; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.blockquote-reverse {
  padding-right: 1rem;
  padding-left: 0;
  text-align: right;
  border-right: 0.25rem solid #eceeef;
  border-left: 0; }

.blockquote-reverse .blockquote-footer::before {
  content: ""; }

.blockquote-reverse .blockquote-footer::after {
  content: "\00A0 \2014"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #636c72; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f7f7f9;
  border-radius: 0.25rem; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #292b2c;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #292b2c; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 11px;
  padding-left: 11px; }
  @media (min-width: 576px) {
    .container {
      padding-right: 11px;
      padding-left: 11px; } }
  @media (min-width: 768px) {
    .container {
      padding-right: 11px;
      padding-left: 11px; } }
  @media (min-width: 992px) {
    .container {
      padding-right: 11px;
      padding-left: 11px; } }
  @media (min-width: 1200px) {
    .container {
      padding-right: 11px;
      padding-left: 11px; } }
  @media (min-width: 576px) {
    .container {
      width: 540px;
      max-width: 100%; } }
  @media (min-width: 768px) {
    .container {
      width: 720px;
      max-width: 100%; } }
  @media (min-width: 992px) {
    .container {
      width: 960px;
      max-width: 100%; } }
  @media (min-width: 1200px) {
    .container {
      width: 1140px;
      max-width: 100%; } }

.container-fluid {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 11px;
  padding-left: 11px; }
  @media (min-width: 576px) {
    .container-fluid {
      padding-right: 11px;
      padding-left: 11px; } }
  @media (min-width: 768px) {
    .container-fluid {
      padding-right: 11px;
      padding-left: 11px; } }
  @media (min-width: 992px) {
    .container-fluid {
      padding-right: 11px;
      padding-left: 11px; } }
  @media (min-width: 1200px) {
    .container-fluid {
      padding-right: 11px;
      padding-left: 11px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -11px;
  margin-left: -11px; }
  @media (min-width: 576px) {
    .row {
      margin-right: -11px;
      margin-left: -11px; } }
  @media (min-width: 768px) {
    .row {
      margin-right: -11px;
      margin-left: -11px; } }
  @media (min-width: 992px) {
    .row {
      margin-right: -11px;
      margin-left: -11px; } }
  @media (min-width: 1200px) {
    .row {
      margin-right: -11px;
      margin-left: -11px; } }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 11px;
  padding-left: 11px; }
  @media (min-width: 576px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 11px;
      padding-left: 11px; } }
  @media (min-width: 768px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 11px;
      padding-left: 11px; } }
  @media (min-width: 992px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 11px;
      padding-left: 11px; } }
  @media (min-width: 1200px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 11px;
      padding-left: 11px; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.pull-0 {
  right: auto; }

.pull-1 {
  right: 8.33333%; }

.pull-2 {
  right: 16.66667%; }

.pull-3 {
  right: 25%; }

.pull-4 {
  right: 33.33333%; }

.pull-5 {
  right: 41.66667%; }

.pull-6 {
  right: 50%; }

.pull-7 {
  right: 58.33333%; }

.pull-8 {
  right: 66.66667%; }

.pull-9 {
  right: 75%; }

.pull-10 {
  right: 83.33333%; }

.pull-11 {
  right: 91.66667%; }

.pull-12 {
  right: 100%; }

.push-0 {
  left: auto; }

.push-1 {
  left: 8.33333%; }

.push-2 {
  left: 16.66667%; }

.push-3 {
  left: 25%; }

.push-4 {
  left: 33.33333%; }

.push-5 {
  left: 41.66667%; }

.push-6 {
  left: 50%; }

.push-7 {
  left: 58.33333%; }

.push-8 {
  left: 66.66667%; }

.push-9 {
  left: 75%; }

.push-10 {
  left: 83.33333%; }

.push-11 {
  left: 91.66667%; }

.push-12 {
  left: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-sm-0 {
    right: auto; }
  .pull-sm-1 {
    right: 8.33333%; }
  .pull-sm-2 {
    right: 16.66667%; }
  .pull-sm-3 {
    right: 25%; }
  .pull-sm-4 {
    right: 33.33333%; }
  .pull-sm-5 {
    right: 41.66667%; }
  .pull-sm-6 {
    right: 50%; }
  .pull-sm-7 {
    right: 58.33333%; }
  .pull-sm-8 {
    right: 66.66667%; }
  .pull-sm-9 {
    right: 75%; }
  .pull-sm-10 {
    right: 83.33333%; }
  .pull-sm-11 {
    right: 91.66667%; }
  .pull-sm-12 {
    right: 100%; }
  .push-sm-0 {
    left: auto; }
  .push-sm-1 {
    left: 8.33333%; }
  .push-sm-2 {
    left: 16.66667%; }
  .push-sm-3 {
    left: 25%; }
  .push-sm-4 {
    left: 33.33333%; }
  .push-sm-5 {
    left: 41.66667%; }
  .push-sm-6 {
    left: 50%; }
  .push-sm-7 {
    left: 58.33333%; }
  .push-sm-8 {
    left: 66.66667%; }
  .push-sm-9 {
    left: 75%; }
  .push-sm-10 {
    left: 83.33333%; }
  .push-sm-11 {
    left: 91.66667%; }
  .push-sm-12 {
    left: 100%; }
  .offset-sm-0 {
    margin-left: 0%; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-md-0 {
    right: auto; }
  .pull-md-1 {
    right: 8.33333%; }
  .pull-md-2 {
    right: 16.66667%; }
  .pull-md-3 {
    right: 25%; }
  .pull-md-4 {
    right: 33.33333%; }
  .pull-md-5 {
    right: 41.66667%; }
  .pull-md-6 {
    right: 50%; }
  .pull-md-7 {
    right: 58.33333%; }
  .pull-md-8 {
    right: 66.66667%; }
  .pull-md-9 {
    right: 75%; }
  .pull-md-10 {
    right: 83.33333%; }
  .pull-md-11 {
    right: 91.66667%; }
  .pull-md-12 {
    right: 100%; }
  .push-md-0 {
    left: auto; }
  .push-md-1 {
    left: 8.33333%; }
  .push-md-2 {
    left: 16.66667%; }
  .push-md-3 {
    left: 25%; }
  .push-md-4 {
    left: 33.33333%; }
  .push-md-5 {
    left: 41.66667%; }
  .push-md-6 {
    left: 50%; }
  .push-md-7 {
    left: 58.33333%; }
  .push-md-8 {
    left: 66.66667%; }
  .push-md-9 {
    left: 75%; }
  .push-md-10 {
    left: 83.33333%; }
  .push-md-11 {
    left: 91.66667%; }
  .push-md-12 {
    left: 100%; }
  .offset-md-0 {
    margin-left: 0%; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-lg-0 {
    right: auto; }
  .pull-lg-1 {
    right: 8.33333%; }
  .pull-lg-2 {
    right: 16.66667%; }
  .pull-lg-3 {
    right: 25%; }
  .pull-lg-4 {
    right: 33.33333%; }
  .pull-lg-5 {
    right: 41.66667%; }
  .pull-lg-6 {
    right: 50%; }
  .pull-lg-7 {
    right: 58.33333%; }
  .pull-lg-8 {
    right: 66.66667%; }
  .pull-lg-9 {
    right: 75%; }
  .pull-lg-10 {
    right: 83.33333%; }
  .pull-lg-11 {
    right: 91.66667%; }
  .pull-lg-12 {
    right: 100%; }
  .push-lg-0 {
    left: auto; }
  .push-lg-1 {
    left: 8.33333%; }
  .push-lg-2 {
    left: 16.66667%; }
  .push-lg-3 {
    left: 25%; }
  .push-lg-4 {
    left: 33.33333%; }
  .push-lg-5 {
    left: 41.66667%; }
  .push-lg-6 {
    left: 50%; }
  .push-lg-7 {
    left: 58.33333%; }
  .push-lg-8 {
    left: 66.66667%; }
  .push-lg-9 {
    left: 75%; }
  .push-lg-10 {
    left: 83.33333%; }
  .push-lg-11 {
    left: 91.66667%; }
  .push-lg-12 {
    left: 100%; }
  .offset-lg-0 {
    margin-left: 0%; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-xl-0 {
    right: auto; }
  .pull-xl-1 {
    right: 8.33333%; }
  .pull-xl-2 {
    right: 16.66667%; }
  .pull-xl-3 {
    right: 25%; }
  .pull-xl-4 {
    right: 33.33333%; }
  .pull-xl-5 {
    right: 41.66667%; }
  .pull-xl-6 {
    right: 50%; }
  .pull-xl-7 {
    right: 58.33333%; }
  .pull-xl-8 {
    right: 66.66667%; }
  .pull-xl-9 {
    right: 75%; }
  .pull-xl-10 {
    right: 83.33333%; }
  .pull-xl-11 {
    right: 91.66667%; }
  .pull-xl-12 {
    right: 100%; }
  .push-xl-0 {
    left: auto; }
  .push-xl-1 {
    left: 8.33333%; }
  .push-xl-2 {
    left: 16.66667%; }
  .push-xl-3 {
    left: 25%; }
  .push-xl-4 {
    left: 33.33333%; }
  .push-xl-5 {
    left: 41.66667%; }
  .push-xl-6 {
    left: 50%; }
  .push-xl-7 {
    left: 58.33333%; }
  .push-xl-8 {
    left: 66.66667%; }
  .push-xl-9 {
    left: 75%; }
  .push-xl-10 {
    left: 83.33333%; }
  .push-xl-11 {
    left: 91.66667%; }
  .push-xl-12 {
    left: 100%; }
  .offset-xl-0 {
    margin-left: 0%; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e8eaec; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e8eaec; }
  .table tbody + tbody {
    border-top: 2px solid #e8eaec; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e8eaec; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e8eaec; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #e8eaec; }

.table-hover tbody tr:hover {
  background-color: #c5cad0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #c5cad0; }

.table-hover .table-active:hover {
  background-color: #b7bdc5; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #b7bdc5; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dff0d8; }

.table-hover .table-success:hover {
  background-color: #d0e9c6; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d0e9c6; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d9edf7; }

.table-hover .table-info:hover {
  background-color: #c4e3f3; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c4e3f3; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf8e3; }

.table-hover .table-warning:hover {
  background-color: #faf2cc; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #faf2cc; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2dede; }

.table-hover .table-danger:hover {
  background-color: #ebcccc; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ebcccc; }

.thead-inverse th {
  color: #fff;
  background-color: #3c4e62; }

.thead-default th {
  color: #464a4c;
  background-color: #e8eaec; }

.table-inverse {
  color: #fff;
  background-color: #3c4e62; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #fff; }
  .table-inverse.table-bordered {
    border: 0; }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive.table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #464a4c;
    background-color: #fff;
    border-color: #5cb3fd;
    outline: none; }
  .form-control::placeholder {
    color: #636c72;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #eceeef;
    opacity: 1; }
  .form-control:disabled {
    cursor: not-allowed; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #464a4c;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0; }

.col-form-label-lg {
  padding-top: calc(0.75rem - 1px * 2);
  padding-bottom: calc(0.75rem - 1px * 2);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem; }

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem; }

.form-control-static {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-static.form-control-sm, .form-control-static.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: 1.8125rem; }

.form-control-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: 3.16667rem; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #636c72;
    cursor: not-allowed; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
  cursor: pointer; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input:only-child {
    position: static; }

.form-check-inline {
  display: inline-block; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }
  .form-check-inline + .form-check-inline {
    margin-left: 0.75rem; }

.form-control-feedback {
  margin-top: 0.25rem; }

.form-control-success,
.form-control-warning,
.form-control-danger {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.5625rem;
  background-size: 1.125rem 1.125rem; }

.has-success .form-control-feedback,
.has-success .form-control-label,
.has-success .col-form-label,
.has-success .form-check-label,
.has-success .custom-control {
  color: #5cb85c; }

.has-success .form-control {
  border-color: #5cb85c; }

.has-success .input-group-addon {
  color: #5cb85c;
  border-color: #5cb85c;
  background-color: #eaf6ea; }

.has-success .form-control-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E"); }

.has-warning .form-control-feedback,
.has-warning .form-control-label,
.has-warning .col-form-label,
.has-warning .form-check-label,
.has-warning .custom-control {
  color: #ff8c00; }

.has-warning .form-control {
  border-color: #ff8c00; }

.has-warning .input-group-addon {
  color: #ff8c00;
  border-color: #ff8c00;
  background-color: #ffe8cc; }

.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff8c00' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E"); }

.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .col-form-label,
.has-danger .form-check-label,
.has-danger .custom-control {
  color: #dc143c; }

.has-danger .form-control {
  border-color: #dc143c; }

.has-danger .input-group-addon {
  color: #dc143c;
  border-color: #dc143c;
  background-color: #fac3ce; }

.has-danger .form-control-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc143c' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E"); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-static {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.ant-form-item-label label {
  font-size: large;
  color: #41ba7a; }

.ant-form-item-explain {
  min-height: 18px; }

.btn {
  display: inline-block;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.25); }
  .btn.disabled, .btn:disabled {
    cursor: not-allowed;
    opacity: .65; }
  .btn:active, .btn.active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8; }
  .btn-primary:hover {
    color: #fff;
    background-color: #025aa5;
    border-color: #01549b; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #0275d8;
    border-color: #0275d8; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #025aa5;
    background-image: none;
    border-color: #01549b; }

.btn-secondary {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc; }
  .btn-secondary:hover {
    color: #292b2c;
    background-color: #e6e6e6;
    border-color: #adadad; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #fff;
    border-color: #ccc; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #292b2c;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad; }

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de; }
  .btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #2aabd2; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #5bc0de;
    border-color: #5bc0de; }
  .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #31b0d5;
    background-image: none;
    border-color: #2aabd2; }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c; }
  .btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #419641; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #5cb85c;
    border-color: #5cb85c; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #449d44;
    background-image: none;
    border-color: #419641; }

.btn-warning {
  color: #fff;
  background-color: #ff8c00;
  border-color: #ff8c00; }
  .btn-warning:hover {
    color: #fff;
    background-color: #cc7000;
    border-color: #c26a00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 2px rgba(255, 140, 0, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ff8c00;
    border-color: #ff8c00; }
  .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7000;
    background-image: none;
    border-color: #c26a00; }

.btn-danger {
  color: #fff;
  background-color: #dc143c;
  border-color: #dc143c; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ad102f;
    border-color: #a40f2d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 2px rgba(220, 20, 60, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc143c;
    border-color: #dc143c; }
  .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ad102f;
    background-image: none;
    border-color: #a40f2d; }

.btn-outline-primary {
  color: #0275d8;
  background-image: none;
  background-color: transparent;
  border-color: #0275d8; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0275d8;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8; }

.btn-outline-secondary {
  color: #ccc;
  background-image: none;
  background-color: transparent;
  border-color: #ccc; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #ccc;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc; }

.btn-outline-info {
  color: #5bc0de;
  background-image: none;
  background-color: transparent;
  border-color: #5bc0de; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #5bc0de;
    border-color: #5bc0de; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #5bc0de;
    background-color: transparent; }
  .btn-outline-info:active, .btn-outline-info.active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #5bc0de;
    border-color: #5bc0de; }

.btn-outline-success {
  color: #5cb85c;
  background-image: none;
  background-color: transparent;
  border-color: #5cb85c; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #5cb85c;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c; }

.btn-outline-warning {
  color: #ff8c00;
  background-image: none;
  background-color: transparent;
  border-color: #ff8c00; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff8c00;
    border-color: #ff8c00; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 2px rgba(255, 140, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ff8c00;
    background-color: transparent; }
  .btn-outline-warning:active, .btn-outline-warning.active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ff8c00;
    border-color: #ff8c00; }

.btn-outline-danger {
  color: #dc143c;
  background-image: none;
  background-color: transparent;
  border-color: #dc143c; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc143c;
    border-color: #dc143c; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 2px rgba(220, 20, 60, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc143c;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc143c;
    border-color: #dc143c; }

.btn-link {
  font-weight: normal;
  color: #0275d8;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active {
    border-color: transparent; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #014c8c;
    text-decoration: underline;
    background-color: transparent; }
  .btn-link:disabled {
    color: #636c72; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.clearfix::after {
  display: block;
  content: "";
  clear: both; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.flex-first {
  order: -1; }

.flex-last {
  order: 1; }

.flex-unordered {
  order: 0; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-first {
    order: -1; }
  .flex-sm-last {
    order: 1; }
  .flex-sm-unordered {
    order: 0; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-first {
    order: -1; }
  .flex-md-last {
    order: 1; }
  .flex-md-unordered {
    order: 0; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-first {
    order: -1; }
  .flex-lg-last {
    order: 1; }
  .flex-lg-unordered {
    order: 0; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-first {
    order: -1; }
  .flex-xl-last {
    order: 1; }
  .flex-xl-unordered {
    order: 0; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1030; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

.w-25 {
  width: 25% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-75 {
  height: 75% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.m-2 {
  margin: 0.5rem 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.m-3 {
  margin: 1rem 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-4 {
  margin: 1.5rem 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 3rem 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-1 {
  padding: 0.25rem 0.25rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.p-2 {
  padding: 0.5rem 0.5rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.p-3 {
  padding: 1rem 1rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-4 {
  padding: 1.5rem 1.5rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-5 {
  padding: 3rem 3rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-sm-0 {
    padding: 0 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-md-0 {
    padding: 0 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-lg-0 {
    padding: 0 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 0 !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem 0.25rem !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem 0.5rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem 1rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem 3rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-xl-0 {
    padding: 0 0 !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem 0.25rem !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem 0.5rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem 1rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem 3rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-muted {
  color: #636c72 !important; }

a.text-muted:focus, a.text-muted:hover {
  color: #4b5257 !important; }

.text-primary {
  color: #0275d8 !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #025aa5 !important; }

.text-success {
  color: #5cb85c !important; }

a.text-success:focus, a.text-success:hover {
  color: #449d44 !important; }

.text-info {
  color: #5bc0de !important; }

a.text-info:focus, a.text-info:hover {
  color: #31b0d5 !important; }

.text-warning {
  color: #ff8c00 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #cc7000 !important; }

.text-danger {
  color: #dc143c !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #ad102f !important; }

.text-gray-dark {
  color: #292b2c !important; }

a.text-gray-dark:focus, a.text-gray-dark:hover {
  color: #101112 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.invisible {
  visibility: hidden !important; }

.hidden-xs-up {
  display: none !important; }

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }
