:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

.user-bg {
  height: 180px;
  border-radius: 6px 6px 0 0;
  background-size: cover;
  background-position: 0 50%; }

.user-info {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -58px;
  margin-bottom: 1.42857rem; }
  .user-info .user-img {
    box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    font-size: 32px; }
    .user-info .user-img .ant-avatar {
      margin: 0; }
  .user-info .user-name {
    margin-bottom: 0; }
  .user-info .user-role {
    opacity: 0.5; }
  .user-info .user-link {
    color: #4a505c; }
    .user-info .user-link:hover {
      color: #2fa7ff; }
