:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent; } }

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both; }

.tc-input {
  border-radius: 20px;
  color: #4a505c;
  display: block;
  font-size: 1rem;
  line-height: 1.42857;
  outline: none;
  width: 100%; }
  .tc-input .tc-input-wrap {
    align-items: center;
    background-color: rgba(74, 80, 92, 0.08);
    background-clip: padding-box;
    border: 1px solid rgba(74, 80, 92, 0.08);
    border-radius: inherit;
    cursor: text;
    display: flex;
    outline: none;
    padding: 0 1.42857rem;
    position: relative;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
    will-change: background-color, border, box-shadow, color;
    width: 100%; }
    .tc-input .tc-input-wrap .input-infix {
      flex: auto;
      min-width: 5px; }
    .tc-input .tc-input-wrap .input-control {
      background: none;
      border: 0;
      border-radius: inherit;
      box-shadow: none;
      color: inherit;
      cursor: inherit;
      display: block;
      font: inherit;
      font-weight: 400;
      margin: -1px 0;
      outline: none;
      padding: 0.71429rem 0.2em;
      width: 100%; }
      .tc-input .tc-input-wrap .input-control::-webkit-input-placeholder {
        color: currentColor;
        font-weight: normal;
        opacity: 0.4; }
      .tc-input .tc-input-wrap .input-control:-moz-placeholder {
        color: currentColor;
        font-weight: normal;
        opacity: 0.4; }
      .tc-input .tc-input-wrap .input-control::-moz-placeholder {
        color: currentColor;
        font-weight: normal;
        opacity: 0.4; }
      .tc-input .tc-input-wrap .input-control:-ms-input-placeholder {
        color: currentColor;
        font-weight: normal;
        opacity: 0.4; }
    .tc-input .tc-input-wrap .input-prefix,
    .tc-input .tc-input-wrap .input-suffix {
      color: #4a505c;
      flex: none;
      padding: 0 0.2em; }
    .tc-input .tc-input-wrap .input-prefix {
      margin-right: 0.2em; }
    .tc-input .tc-input-wrap .input-suffix {
      margin-left: 0.2em; }
    .tc-input .tc-input-wrap .input-icon {
      font-size: 1.2em;
      opacity: 0.4; }
    .tc-input .tc-input-wrap .char-limiting {
      align-items: center;
      color: #4a505c;
      display: flex;
      font-size: 0.9em;
      justify-content: center;
      padding: 0 0.2em;
      pointer-events: none; }
  .tc-input:hover {
    color: #4a505c; }
    .tc-input:hover .tc-input-wrap {
      background-color: rgba(74, 80, 92, 0.08);
      border-color: rgba(74, 80, 92, 0.08);
      box-shadow: none; }
  .tc-input.input-focus {
    color: #4a505c; }
    .tc-input.input-focus .tc-input-wrap {
      background-color: #fff;
      border-color: rgba(74, 80, 92, 0.08);
      box-shadow: none; }
  .tc-input.ng-invalid.ng-touched .tc-input-wrap,
  .has-error .tc-input .tc-input-wrap {
    background-color: rgba(237, 37, 61, 0.1);
    border: 1px solid #ed253d; }
  .tc-input.input-sm {
    border-radius: 16px;
    font-size: 0.9rem;
    line-height: 1.5873; }
    .tc-input.input-sm .tc-input-wrap .input-control {
      padding-top: 0.35714rem;
      padding-bottom: 0.35714rem; }
    .tc-input.input-sm .tc-input-wrap .input-icon {
      font-size: 1.1em; }
  .tc-input.input-lg {
    border-radius: 30px;
    font-size: 1.3rem;
    line-height: 1.0989; }
    .tc-input.input-lg .tc-input-wrap .input-control {
      padding-top: 1.07143rem;
      padding-bottom: 1.07143rem; }
  .tc-input.input-disabled, .tc-input.input-readonly {
    color: #bdbdbd; }
    .tc-input.input-disabled .tc-input-wrap, .tc-input.input-readonly .tc-input-wrap {
      background-color: rgba(204, 204, 204, 0.1);
      border-color: rgba(204, 204, 204, 0.5);
      box-shadow: none !important;
      cursor: not-allowed; }
      .tc-input.input-disabled .tc-input-wrap .input-control, .tc-input.input-readonly .tc-input-wrap .input-control {
        pointer-events: none; }
