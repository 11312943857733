:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

@keyframes loaderAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.navbar-skeleton {
  align-items: center;
  background: inherit;
  display: flex;
  height: 100%;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 0 0.71429rem;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s opacity 0s ease-in-out, 0s visibility 0s ease-in-out;
  z-index: 999; }
  .navbar-skeleton.invisible {
    opacity: 0;
    transition: 0.2s opacity 0.2s ease-in-out, 0s visibility 0.4s ease-in-out;
    visibility: hidden; }
  .navbar-skeleton .bg {
    background: rgba(255, 255, 255, 0.3); }
  .navbar-skeleton .bg-1 {
    background: rgba(8, 85, 78, 0.3); }
  .navbar-skeleton .bg-2 {
    background: #08554e; }
  .navbar-skeleton .bg-3 {
    background: rgba(8, 85, 78, 0.5); }
  .navbar-skeleton .bg-4 {
    background: #ebebeb; }
  .navbar-skeleton .left-part {
    flex-grow: 1; }
  .navbar-skeleton .navbar-button {
    display: block;
    background: rgba(47, 167, 255, 0.5);
    border-radius: 6px;
    height: 30px;
    margin-right: 1.42857rem;
    width: 35px; }
  .navbar-skeleton .search {
    border-radius: 20px;
    display: block;
    height: 40px;
    max-width: 53.57143rem;
    width: 69%; }
  .navbar-skeleton .icon-box {
    position: relative; }
    .navbar-skeleton .icon-box .icon {
      border-radius: 5px;
      display: block;
      height: 20px;
      width: 20px; }
    .navbar-skeleton .icon-box .badge {
      background: #08554e;
      border-radius: 20px;
      display: block;
      height: 16px;
      position: absolute;
      right: -8px;
      top: -10px;
      width: 16px; }
  .navbar-skeleton .avatar {
    border-radius: 20px;
    display: block;
    height: 40px;
    margin: 0 18px 0 25px;
    width: 40px; }
  .navbar-skeleton .sk-logo {
    border-radius: 5px;
    display: block;
    height: 2.85714rem;
    margin-right: 1.42857rem;
    max-width: 147px;
    width: 100%; }
  .navbar-skeleton .animated-bg {
    overflow: hidden;
    position: relative; }
  .navbar-skeleton .animated-bg:before {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loaderAnimation;
    animation-timing-function: linear;
    background: transparent;
    background: linear-gradient(to right, transparent 10%, rgba(255, 255, 255, 0.5) 18%, transparent 33%);
    background-size: 800px 100%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .navbar-skeleton.vertical {
    flex-direction: column; }
    .navbar-skeleton.vertical .logo-block {
      align-items: center;
      background: #fff;
      position: absolute;
      display: flex;
      padding: 1.42857rem 2.14286rem;
      height: 4.28571rem;
      left: 0;
      top: 0;
      width: 100%; }
      .navbar-skeleton.vertical .logo-block .logo {
        border-radius: 6px;
        height: 100%;
        width: 100%; }
    .navbar-skeleton.vertical .top-part,
    .navbar-skeleton.vertical .bottom-part {
      padding: 3.57143rem 1.42857rem 1.42857rem;
      width: 100%; }
    .navbar-skeleton.vertical .bottom-part {
      padding-bottom: 2.14286rem; }
    .navbar-skeleton.vertical .sk-logo {
      margin: 0; }
    .navbar-skeleton.vertical .sk-menu {
      margin: 3.21429rem 0 0; }
      .navbar-skeleton.vertical .sk-menu .sk-menu-item {
        border-radius: 3px;
        display: block;
        height: 1.07143rem;
        margin: 1.07143rem 0 0; }
        .navbar-skeleton.vertical .sk-menu .sk-menu-item.menu-header {
          width: 50%; }
    .navbar-skeleton.vertical .sk-button {
      border-radius: 40px;
      height: 2.14286rem;
      margin-top: 2.14286rem;
      margin-left: -0.71429rem; }
    .navbar-skeleton.vertical .sk-group-item {
      border-radius: 3px;
      display: block;
      height: 1.42857rem;
      margin: 0 0.71429rem 0 0;
      width: 5.71429rem; }
    .navbar-skeleton.vertical .horizontal-menu {
      align-items: center;
      align-self: flex-start;
      display: none;
      height: 100%; }
      .navbar-skeleton.vertical .horizontal-menu .sk-menu-item {
        border-radius: 3px;
        display: block;
        height: 0.71429rem;
        margin: 0 0.71429rem 0 0;
        width: 5.71429rem; }
  @media (min-width: 992px) {
    .horizontal-vertical .navbar-skeleton.vertical .top-part,
    .horizontal-vertical .navbar-skeleton.vertical .bottom-part {
      display: none; }
    .horizontal-vertical .navbar-skeleton.vertical .horizontal-menu {
      display: flex; } }
