:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

.actions {
  align-items: center;
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  margin: 0 !important; }
  .actions .item {
    margin: 0 0.71429rem;
    cursor: pointer; }
    .actions .item.icon {
      transition: background 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out; }
    .actions .item .icon.log-out {
      margin: 0 !important;
      margin-left: auto !important;
      opacity: 0.2;
      color: #000 !important; }
    .actions .item .tc-badge {
      justify-content: center;
      font-size: 0.8em;
      min-width: 18px;
      height: 18px;
      padding-left: 2px;
      padding-right: 2px;
      position: absolute;
      right: -5px;
      text-align: center;
      top: -5px; }
    .actions .item:hover .tc-icon {
      opacity: 1; }
    .actions .item.active .tc-icon {
      color: #08554e;
      opacity: 1; }

.item-link {
  transition: color 0.2s ease-in-out; }
  .item-link:hover {
    color: #08554e; }

.notification-icon {
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out; }
  .notification-icon:hover {
    opacity: 1; }

.dropdown-link-wrap {
  position: relative; }

.dropdown-title {
  font-size: 1.1em;
  margin: 0;
  padding: 0.71429rem 1.42857rem; }
