:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

.color-field {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 38px;
  position: relative;
  min-width: 90px; }
  .color-field:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: '';
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    right: 1px; }
  .color-field .circle {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    height: 20px;
    position: absolute;
    overflow: hidden;
    transition: border-radius 0.2s cubic-bezier(0.7, 0, 0.3, 1), height 0.2s cubic-bezier(0.7, 0, 0.3, 1), left 0.2s cubic-bezier(0.7, 0, 0.3, 1), transform 0.2s cubic-bezier(0.7, 0, 0.3, 1), width 0.2s cubic-bezier(0.7, 0, 0.3, 1);
    width: 20px;
    will-change: border-radius, height, left, transform, width;
    z-index: 2; }
    .color-field .circle .bg {
      display: block;
      left: 0;
      padding-top: 100%;
      position: absolute;
      right: 0;
      top: 9px;
      transform: translate(0, -50%);
      transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1); }
      .color-field .circle .bg .white {
        border-radius: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        transition: border-radius 0.2s cubic-bezier(0.7, 0, 0.3, 1);
        width: 100%;
        will-change: border-radius; }
      .color-field .circle .bg .transparent {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAJUlEQVQYV2O8devWfwY0oKamxoguxjgUFKI7GsTH5m4M3w1ChQBEjCXMAbX0MQAAAABJRU5ErkJggg==) repeat left top;
        background-repeat: repeat;
        background-position: 0 0;
        background-size: 12px;
        border-radius: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        transition: border-radius 0.2s cubic-bezier(0.7, 0, 0.3, 1);
        width: 100%;
        will-change: border-radius; }
      .color-field .circle .bg .bg-color {
        border-radius: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        transition: border-radius 0.2s cubic-bezier(0.7, 0, 0.3, 1);
        width: 100%;
        will-change: border-radius; }
    .color-field .circle .cursor {
      border-radius: 50% !important;
      border: 1px solid #fff;
      display: block;
      height: 9px;
      left: 50%;
      margin: -4.5px 0 0 -4.5px;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: scale(0);
      transform-origin: 50% 50%;
      transition: transform 0.2s cubic-bezier(0.7, 0, 0.3, 1), opacity 0.2s cubic-bezier(0.7, 0, 0.3, 1);
      width: 9px;
      z-index: 3; }
      .color-field .circle .cursor:after {
        background: #fff;
        content: '';
        display: block;
        height: 14px;
        position: absolute;
        margin: -3px 0 0 3px;
        width: 1px; }
      .color-field .circle .cursor:before {
        background: #fff;
        content: '';
        display: block;
        height: 1px;
        margin: 3px 0 0 -3px;
        position: absolute;
        width: 14px; }
      .color-field .circle .cursor.black {
        border-color: #000; }
        .color-field .circle .cursor.black:before, .color-field .circle .cursor.black:after {
          background: #000; }
    .color-field .circle:hover .cursor {
      opacity: 1;
      transform: scale(1);
      transition: transform 0.2s cubic-bezier(0.7, 0, 0.3, 1) 0.1s, opacity 0.1s cubic-bezier(0.7, 0, 0.3, 1); }
  .color-field .selected-color-value {
    margin-left: auto; }
  .color-field:hover .circle {
    border-radius: 0;
    height: 100%;
    transform: translate(0, 0);
    width: calc(100% + 1px); }
    .color-field:hover .circle :not(.cursor) {
      border-radius: 0 !important; }
  .color-field.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .color-field.gradient {
    min-width: 165px; }
    .color-field.gradient .circle:not(:first-child) {
      left: 12px; }
    .color-field.gradient:hover .circle {
      width: 50%; }
      .color-field.gradient:hover .circle:not(:first-child) {
        left: 50%; }
    .color-field.gradient :first-child {
      z-index: 2; }
