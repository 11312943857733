:root {
  --main-color: #4a505c;
  --main-bg: #f4f5f8;
  --boxed-width: 1140px; }

.group-bg {
  height: 140px;
  border-radius: 6px 6px 0 0;
  background-size: cover;
  background-position: 0 50%; }

.card-body {
  align-items: center;
  padding: 0 1.42857rem 1.42857rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -38px; }
  .card-body .info-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .card-body .info-block > * {
      margin-bottom: 1.42857rem; }
  .card-body .avatar-block {
    text-align: center; }
    .card-body .avatar-block .ant-avatar {
      margin: 0; }
  .card-body .title-block .group-title {
    margin: 0; }
  .card-body .connections-block {
    text-align: center; }
    .card-body .connections-block .avatar-group {
      margin-bottom: 0.35714rem; }
    .card-body .connections-block .ant-avatar {
      border: 2px solid #fff; }
  .card-body .group-link {
    color: #4a505c; }
    .card-body .group-link:hover {
      color: #2fa7ff; }

.secondary-text {
  opacity: 0.6; }
